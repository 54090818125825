<template>
  <form @submit.prevent="loginUser">
    <div class="form-logo">
      <img src="/images/logos/logo.png" alt="Viene Bien" title="Viene Bien" />
    </div>
    <div class="form-control">
      <label for="email">Correo electrónico:</label>
      <input
        type="email"
        name="email"
        placeholder="Ingrese su correo"
        v-model="userForm.email"
        required
      />
    </div>
    <div class="form-control">
      <label for="password">Contraseña:</label>
      <div class="input-w-icon">
        <input
          type="password"
          name="password"
          ref="inputPassword"
          placeholder="Ingrese su contraseña"
          v-model="userForm.password"
          required
        />

        <i
          @click="changeType"
          :class="[!showEyesIcon ? 'fas fa-eye' : 'fas fa-eye-slash']"
        ></i>
      </div>
    </div>
    <div class="text-center" v-if="validate.isLoading">
      <Loading />
    </div>
    <Message
      v-else-if="!validate.isLoading && validate.message"
      :messageType="validate.messageType"
      :message="validate.message"
    />
    <div v-if="!validate.isLoading" class="text-center mt-2">
      <button type="submit" class="btn btn-primary2">Iniciar Sesión</button>
    </div>

    <!-- 
      <div class="text-center mt-1">
        <router-link class="link" :to="{ name: 'register' }">Registrarme</router-link>
      </div> 
    -->
  </form>
</template>

<script>
import { defineAsyncComponent, onBeforeMount } from 'vue';

import useToogglePass from '@/modules/auth/composables/useTogglePass';
import useAuthentication from '@/modules/auth/composables/useAuthentication';
import useAuth from '@/modules/auth/composables/useAuth';

export default {
  components: {
    Loading: defineAsyncComponent(() =>
      import(/* webpackChunkName: "Loading" */ '@/components/Loading.vue')
    ),
    Message: defineAsyncComponent(() =>
      import(/* webpackChunkName: "Message" */ '@/components/Message.vue')
    ),
  },
  setup() {
    onBeforeMount(() => {
      const { validateAuthentication } = useAuthentication();
      validateAuthentication();
    });
    return {
      ...useToogglePass(),
      ...useAuth(),
    };
  },
};
</script>
